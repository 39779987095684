<template>
  <page-template>
    <main-block>
      <block-head :title="$t('Appointment Agents')+' / '" :description="$t('pages.ap_agent_profile.appointments_desc')">
        <template v-slot:title_extras>
          <strong class="text-primary small">{{$t('Appointments')}}</strong>
        </template>
      </block-head>
      <block-content>
        <card no-padding stretch>
          <template v-slot:grouped>
            <div class="card-inner position-relative card-tools-toggle">
              <card-header title="">
                <card-tools>
                  <div class="form-inline flex-nowrap gx-3 d-none">
                    <div class="form-wrap w-150px">
                      <select class="form-select form-select-md form-control" v-model="bulkSelectedAction">
                        <option value="">{{ $t('Bulk Action') }}</option>
                      </select>
                    </div>
                    <div class="btn-wrap">
                          <span class="d-none d-md-block">
                            <nk-button dim outline type="light" v-on:click="bulkAction" :disabled="!bulkSelectedAction.length">{{ $t('Apply') }}</nk-button>
                          </span>
                      <span class="d-md-none">
                            <nk-button dim outline type="light" v-on:click="bulkAction" is-icon-only-button="" :disabled="!bulkSelectedAction.length">
                              <nio-icon icon="ni-arrow-right"></nio-icon>
                            </nk-button>
                          </span>
                    </div>
                  </div>
                </card-tools>
                <card-tools class="mr-n1">
                  <btn-toolbar>
                    <btn-toolbar-toggle-wrap id="leads_toolbar">
                      <li>
                        <nk-button is-link type=""
                                   is-icon-only-button=""
                                   class="search-toggle"
                                   v-on:click="searchBarActive=true"
                                   :class="{active: searchBarActive}">
                          <nio-icon icon="ni-search"></nio-icon>
                        </nk-button>
                      </li>
                      <li class="btn-toolbar-sep"></li>
                      <li>
                        <drop-down
                            icon="ni-filter-alt"
                            size="lg"
                            direction="right"
                            :dot="( appointments.filters.status !== 0  ? 'primary':'')"
                            :title="$t('Filter')">
                          <template v-slot:body>
                            <row class="gx-6 gy-3">
                              <column default="12" md="6">
                                <label class="overline-title overline-title-alt" for="apl_status_filter">{{ $t('Status') }}</label>
                                <select class="form-control form-control-sm" id="apl_status_filter" v-model="appointments.filters.status">
                                  <option value="0">{{ $t('All') }}</option>
                                  <option v-for="st in leadStatuses" :value="st.id" :key="'stf'+st.id">{{ $t(st.status_text) }}</option>
                                </select>
                              </column>
                            </row>
                          </template>
                          <template v-slot:footer>
                            <nk-button type="dark" dim v-on:click="getAppointments(true)">{{ $t('Filter') }}</nk-button>
                            <a href="javascript:;" v-on:click="[appointments.filters.status=0,getAppointments(true)]">{{ $t('Reset Filter') }}</a>
                          </template>
                        </drop-down>
                      </li><!-- li -->
                      <li>
                        <drop-down icon="ni ni-setting" title="" size="xs" direction="right">
                          <ul class="link-check">
                            <li><span>{{ $t('Order') }}</span></li>
                            <li :class="{active: appointments.order === 'asc'}"><a href="javascript:;" v-on:click="[appointments.order='asc', getAppointments(true)]">{{ $t('ASC') }}</a></li>
                            <li :class="{active: appointments.order === 'desc'}"><a href="javascript:;" v-on:click="[appointments.order='desc', getAppointments(true)]">{{ $t('DESC') }}</a></li>
                          </ul>
                        </drop-down>
                      </li><!-- li -->
                    </btn-toolbar-toggle-wrap>
                  </btn-toolbar>
                </card-tools>
              </card-header>
              <card-search
                  :placeholder="$t('Search by')+': '+$t('Lead id')+', '+$t('Agent id')+' | '+$t('Name')+' | '+$t('Email')"
                  :is-active="searchBarActive"
                  @do-search="searchRecords"
                  :search-term="searchFilter"
                  @close-search="[searchBarActive=false, searchRecords('')]"></card-search>
            </div>
            <div class="d-flex py-5 justify-center"  v-if="spinners.gettingLeads">
              <ion-spinner></ion-spinner>
            </div>
            <template v-else>
              <div class="card-inner p-0">
                <special-table>
                  <table-row is-heading>
                    <table-col has-check-box class="d-none">
                      <b-check-box id="asl_select_all" v-model="selectAll"></b-check-box>
                    </table-col>
                    <table-col>
                      <span class="sub-text">Lead</span>
                    </table-col>
                    <table-col break-point="md">
                      <span class="sub-text">{{$t('Appointment agent')}}</span>
                    </table-col>
                    <table-col break-point="lg">
                      <span class="sub-text">{{$t('Assigned to')}}</span>
                    </table-col>
                    <table-col break-point="md">
                      <nio-icon icon="ni-notes-alt" class="mr-1" style="font-size: 1.1rem;" v-tooltip="$t('Notes')"></nio-icon>
                    </table-col>
                    <table-col break-point="md">{{$t('Assign date')}}</table-col>
                    <table-col break-point="lg">{{$t('Opened')}}</table-col>
                    <table-col break-point="lg">{{$t('Current status')}}</table-col>
                    <table-col class="nk-tb-col-tools"></table-col>
                  </table-row>

                  <table-row v-for="lead in appointments.data" :key="'ld'+lead.id">
                    <table-col has-check-box class="d-none">
                      <b-check-box :id="'lead'+lead.id" v-model="lead.selected" :key="'cb'+lead.id"></b-check-box>
                    </table-col>
                    <table-col class="lh-n">
                      <user-small-card
                          :user-name="lead.first_name+' '+lead.last_name"
                          :user-email="lead.email" :image="lead.dp" :initials="lead.initials" bg-color="info">
                        <template v-slot:more_info>
                          <div class="lh-n small">
                            <div class="text-primary fw-bold">#{{ lead.lead_number}}</div>
                            <star-rating v-show="1===2" :rating="parseFloat(lead.rating)" :increment="0.5" :star-size="12" :show-rating="false" :read-only="true"></star-rating>
                          </div>
                        </template>
                      </user-small-card>
                    </table-col>
                    <table-col break-point="md" class="lh-n">
                      <user-small-card
                          :user-name="lead.ap_agent.first_name+' '+lead.ap_agent.last_name"
                          :user-email="lead.ap_agent.email" :image="lead.ap_agent.dp" :initials="lead.ap_agent.initials" bg-color="primary">
                      </user-small-card>
                    </table-col>
                    <table-col break-point="lg">
                      <user-small-card v-if="lead.assigned_to.advisor_id"
                                       :user-name="lead.assigned_to.first_name+' '+lead.assigned_to.last_name"
                                       :user-email="lead.assigned_to.email" :image="lead.assigned_to.dp" :initials="lead.assigned_to.initials">
                      </user-small-card>
                      <!-- 9 = Appointment canceled -->
                      <span v-else-if="lead.status_id !== 9" class="fw-500 text-danger">{{ $t('Not yet assigned')}}</span>
                    </table-col>
                    <table-col break-point="md">
                      <a href="javascript:;" v-if="lead.notes_count > 0" v-on:click="getLeadNotes(lead.AL_id)" class="d-flex align-items-center">
                        <nio-icon icon="ni-notes-alt" class="mr-1" style="font-size: 1.1rem;"></nio-icon>
                        <span class="text-primary">{{lead.notes_count}}</span>
                      </a>
                    </table-col>
                    <table-col break-point="md">{{ lead.assign_date }}</table-col>
                    <table-col break-point="lg">{{ lead.opened_time }}</table-col>
                    <table-col break-point="lg" :style="{color: lead.status_color}">{{ $t(lead.status_text) }}</table-col>
                    <table-col class="nk-tb-col-tools">
                      <ul class="nk-tb-actions gx-1">
                        <li>
                          <drop-down-list>
                            <list-item icon="ni-eye" :text="$t('View Details')" :to="{name: 'adminLeadDetails', params:{id:lead.id}}"></list-item>
                          </drop-down-list>
                        </li>
                      </ul>
                    </table-col>
                  </table-row>

                </special-table>
              </div>
              <div class="card-inner" v-if="appointments.totalRecords > 10">
                <pagination
                    v-model="appointments.page"
                    :records="appointments.totalRecords"
                    :per-page="10"
                    @paginate="paginationCallback"
                    theme="bootstrap4"
                    :options="{chunk: 5, texts:{count: '||'}}"/>
              </div>
            </template>
          </template>
        </card>
      </block-content>
    </main-block>
  </page-template>
</template>

<script>
import {
  IonSpinner,
  isPlatform, onIonViewWillEnter
} from '@ionic/vue';
import {computed, defineComponent, reactive, ref, watch} from 'vue';
import {BlockContent, BlockHead, Column, MainBlock, Row} from "@/@core/layouts";
import axios from "@/libs/axios"
import {Card, CardHeader, CardSearch, CardTools} from "@core/components/cards";

import { BtnToolbar, BtnToolbarToggleWrap } from "@core/components/btn-toolbar"
import "bootstrap"
import {DropDown, DropDownList} from "@core/components";

import Pagination from "v-pagination-3"
import {SpecialTable, TableCol, TableRow} from "@core/components/special-table";
import {BCheckBox} from "@core/components/bp-form";
import UserSmallCard from "@/views/components/user-cards/UserSmallCard";
import ListItem from "@core/components/dropdown-list/ListItem";

import StarRating from "vue-star-rating";
import {useStore} from "vuex";
import PageTemplate from "@core/layouts/page/PageTemplate";

export default defineComponent({
  components: {
    PageTemplate,
    ListItem,
    DropDownList,
    UserSmallCard,
    BCheckBox,
    SpecialTable,
    TableCol,
    TableRow,
    CardSearch,
    CardTools,
    BlockHead,
    Column,
    Row,
    DropDown,
    BtnToolbar, BtnToolbarToggleWrap,
    CardHeader,
    Card,
    BlockContent,
    MainBlock,
    IonSpinner,
    Pagination,
    StarRating,
  },
  setup() {

    const isDesktop = isPlatform('desktop')
    const store = useStore()

    let appointments = reactive({
      data: [],
      filters:{
        user_filter: 'all',
        status: 0
      },
      order: 'desc',
      page: 1,
      totalRecords: 0,
    })
    let searchFilter  = ref('');

    let spinners = reactive({
      gettingLeads: false,
    })

    let selectAll    = ref(false)
    let searchBarActive = ref(false)

    const paginationCallback = async () => {
      await getAppointments(true)
    }

    watch(searchFilter, () => getAppointments(true))

    const searchRecords = (search) => {
      searchFilter.value = search
    }

    watch(selectAll, (n) => {
      for(let x in appointments.data){
        appointments.data[x].selected = n
      }
    })

    let bulkSelectedAction = ref('')
    const bulkAction = async () => {
      let selectedAL_Ids = []
      for(let x in appointments.data){
        if( appointments.data[x].selected){
          selectedAL_Ids.push( appointments.data[x].AL_id)
        }
      }
      if( selectedAL_Ids.length < 1 ){
        return false
      }
    }

    let cancelToken = false
    const getAppointments = () => {
      if(false !== cancelToken){
        cancelToken()
        cancelToken = false
      }
      spinners.gettingLeads = true
      appointments.data = []
      axios.post('/api/admin/ap_agent/'+appointments.filters.user_filter+'/appointments',
          Object.assign({page: appointments.page, order: appointments.order, search: searchFilter.value}, appointments.filters),
          {cancelToken: new axios.CancelToken( c => { cancelToken = c})}
      ).then( resp => {
        if(resp.data.success){
          for(let x in resp.data.data.records){
            let tmp = resp.data.data.records[x]
            tmp.selected = false
            appointments.data.push(tmp)
          }
        }
        if(appointments.page === 1){
          appointments.totalRecords = resp.data.data.total_records
        }
      }).catch(error => {
            console.log(error)
      }).then(()=> {
        cancelToken = false
        spinners.gettingLeads = false
      })
    }

    onIonViewWillEnter(()=>{ getAppointments() })
    const leadStatuses = computed(()=>{
      let st = []
      for(let x in store.state.general_data.leads_statuses){
        let tmp = store.state.general_data.leads_statuses[x]
        if(tmp.status_text.toLowerCase() !== 'appointment'){
          st.push(tmp)
        }
      }
      return st
    })


    return {
      isDesktop,

      searchFilter,
      paginationCallback,

      searchBarActive,
      selectAll,

      bulkSelectedAction,
      bulkAction,

      getAppointments,
      appointments,
      leadStatuses,
      searchRecords,
      spinners,
    }
  }
});

</script>
